@primaryColor: #6179F9;

.rc-page-loader, .rc-page-loader * {
  box-sizing: border-box;
}

.rc-page-loader {
  height: 15px;
  width: calc(30px * 3);
}

.rc-page-loader .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid @primaryColor;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: rc-page-loader-animation 1000ms ease infinite 0ms;
}

.rc-page-loader .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.rc-page-loader .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.rc-page-loader .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);

}

@keyframes rc-page-loader-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@link-color: #009B72;@primary-color: #009B72;@dark-primary-color: #003829;@light-primary-color: #E1FF68;@ultra-light-primary-color: #F2FDCA;@cashflow-color: #B098FF;@light-cashflow-color: #DCD5F4;@ultra-light-cashflow-color: #F2EEFD;@success-color: #ACE498;@error-color: #FC4848;@splitted-complementary-left-color: #E1FF68;@cashin-color: #81CD66;@cashout-color: #FBA694;@light-cashin-color: #B4F59E;@ultra-light-cashin-color: #E1fdd8;@light-cashout-color: #FFC7BB;@ultra-light-cashout-color: #FDE4DF;@base-color: #26272E;